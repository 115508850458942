/* eslint-disable camelcase, no-console */
import {
	type UserProfile as Profile,
	User,
	UserManager,
	type UserManagerSettings,
	WebStorageStateStore,
	InMemoryWebStorage,
} from "oidc-client-ts";
import join from "lodash/fp/join";

import { mapUserProfile } from "./userProfile";
import { config } from "../../config";
import type { OAuthConfig } from "..";
import type { AccessToken } from "../tokenHandling/tokenSlice";
import type { UserProfile } from "./loginSlice";

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.signinSilent().catch((error: Error) => {
		if (error.message === "login_required") {
			oauthConfig.onSessionExpired();
		} else {
			setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
		}
	});
};

export type SessionRenewedResult = {
	accessToken: AccessToken;
	idToken: Profile;
	profile: UserProfile;
	locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
	accessToken: result.access_token,
	idToken: result.profile,
	locale: result.profile?.locale ?? "en-GB",
	profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
	const redirectUri = config.login.redirectUri;
	const silentRedirectUri = config.login.silentRedirectUri;

	const settings: UserManagerSettings = {
		authority: `${config.login.authority}`,
		client_id: `${config.login.clientId}`,
		loadUserInfo: false,
		redirect_uri: `${redirectUri}`,
		response_type: "code",
		scope: join(" ", config.login.oauthScope),
		silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
		includeIdTokenInSilentRenew: false,
		automaticSilentRenew: true,
		monitorSession: true,
		staleStateAgeInSeconds: 600,
		userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
		filterProtocolClaims: false,
	};

	return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.events.addUserLoaded((user) => {
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
	});

	userManager.events.addUserUnloaded(() => {
		oauthConfig.onSessionExpired();
	});

	userManager.events.addSilentRenewError(() => {
		retrySigninSilent(oauthConfig, userManager);
	});

	userManager.events.addUserSignedOut(() => {
		oauthConfig.onSessionExpired();
	});

	return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig): UserManager => {
	const signinSilent = () => {
		const userSettings = {
			// manual token
			access_token:
				"eyJraWQiOiJjMDNmNDMwMy05OTk4LTRkYmItYjgxNC03OGI0MmIwNmE0NDkiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJwcm9kLXJpby11c2VyczplYWE5MzgzZi04YTZkLTQ0ZjUtYWZlMi01NDUzYTYzY2I1MWIiLCJhenAiOiJhNmExMjlkMi02YTc3LTQyMDYtOWE1NS0wYjM3YmNiMDQyMDkiLCJzY29wZSI6ImFzc2V0LWFkbWluaXN0cmF0aW9uLnJlYWQgcGhvbmUgb3BlbmlkIHByb2ZpbGUgdGFncy5yZWFkIGVtYWlsIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmlhbS5yaW8uY2xvdWQiLCJleHAiOjE3MzA5MTYzMDcsImlhdCI6MTczMDkxMjcwNywianRpIjoiOGU5YzIxZmYtOTFlOC00YTVlLTg5MzMtM2UwMTg1OGRmZWE1IiwiY2xpZW50X2lkIjoiYTZhMTI5ZDItNmE3Ny00MjA2LTlhNTUtMGIzN2JjYjA0MjA5IiwiYWNjb3VudCI6IjYyNzlhODQyLTA4YmItNDBmYy04MDViLTA3YzUxODI0MTRiNyIsInRlbmFudCI6InJpby1icmF6aWwucHJvZCJ9.APF44H7w1K_XYgIrelPcguaBx6jIOAbBpkUdafCFkN4Ji3OaSjwvTKiln8KO1MNXaDC7y8jEXPD7Djh_LdRSQzILnDFUmma692pg5t7orjsItp9MvE3x8BDmUHcF2ioswx1diLoleigUwqPnh02cGFkHkL8FIpi2ToFXJBtfqMtplzKMnsEL4Kjclt8RlsoconNJMIsyVgqkU14b7tSXI7v1pQ9IvtCSPUCNN8EfBotpg3E0IdrD0qEvq-6-qJwSGIAR1KRnDHV0wEWx6Fp0XWbOAZRYPCssUm8SsEViK7AdbC5AhCwQUeF5l8qJvU2Sm9H-0NyUAvpQJpLTucuSdd1xTOeinQ4FAHY_MlPaEkdjzZEUWw6LDovzUuS29XnYSgNBwzbCoocb16FHYRZktcgXigqDgR8WHVltt9nTBx_98Lj1FM3BoVRVTgISSqrlNIdAh_Ups8zuz11dguRb4Ps4fhMA0tNqZfzygUT3Q1Zw_jz58G41S2KLQ2Rs6K4GEM9P5oGVxVhCMt6Kw1Ze_3NmSNX5Hp80Tbz2M6-2vV2GKNQoaHDI9EL-XplbgJv_4FCFUSrC511KZXNhhvxTcKeydBA7Stziec9YogWLiNDb6WMuAyu57LNEpm5q166Qk8LW-fkZYqidm_gtovylusDB5IJIH1VJzKVm2UfyAYc",
			profile: {
				iss: "Issuer Identifier",
				aud: "Audience(s): client_id",
				exp: 10,
				iat: 5,
				account: "local", // mock possible account ids here
				azp: "test-client",
				email: "test@example.com",
				family_name: "Client",
				given_name: "Test",
				name: "Test Client",
				sub: "prod-rio-users:mock-user",
				locale: config.login.mockLocale,
				tenant: config.login.mockTenant,
			},
			id_token: "id_token",
			session_state: "session_state",
			refresh_token: "refresh_token",
			token_type: "token_type",
			scope: "scope",
			expires_at: 10000000,
			state: "state",
		};

		const user = new User(userSettings);
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
		return Promise.resolve(user);
	};

	const clearStaleState = () => {
		console.info("[configuration/login/oidc-session] Stale state cleared");
		return Promise.resolve();
	};

	return { signinSilent, clearStaleState } as UserManager;
};

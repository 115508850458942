import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";
import SimpleTooltip from "@rio-cloud/rio-uikit/components/tooltip/SimpleTooltip";
import useOnlineStatus from "@rio-cloud/rio-uikit/useOnlineStatus";
import { DefaultUserMenu } from "@rio-cloud/rio-user-menu-component";
import IframeResizer from "iframe-resizer-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { config } from "../../config";
import HowToUse from "../../pages/application/HowToUse";
import { ALERTS_ROUTE, DEFAULT_ROUTE, DOWNTIME_ROUTE, NOTIFICATIONS_ROUTE, RECURRENCE_ROUTE } from "../../routes/Router";
import { LinkWithQuery } from "../../components/link/LinkWithQuery";
import ServiceInfo from "./ServiceInfo";

const AppHeader = () => {
	const [showHelpDialog, setShowHelpDialog] = useState(false);
	const environment = import.meta.env.PROD ? "production" : "local";

	const navItems = [
		{
			key: "dashboard",
			route: (
				<LinkWithQuery to={DEFAULT_ROUTE}>
					<FormattedMessage id={"general.app.page.dashboard"} />
				</LinkWithQuery>
			),
		},
		{
			key: "alerts",
			route: (
				<LinkWithQuery to={ALERTS_ROUTE}>
					<FormattedMessage id={"general.app.page.alerts"} />
				</LinkWithQuery>
			),
		},
		{
			key: "notifications",
			route: (
				<LinkWithQuery to={NOTIFICATIONS_ROUTE}>
					<FormattedMessage id={"general.app.page.notifications"} />
				</LinkWithQuery>
			),
		},
		{
			key: "downtime",
			route: (
				<LinkWithQuery to={DOWNTIME_ROUTE}>
					<FormattedMessage id={"general.app.page.downtime"} />
				</LinkWithQuery>
			),
		},
		{
			key: "recurrence",
			route: (
				<LinkWithQuery to={RECURRENCE_ROUTE}>
					<FormattedMessage id={"general.app.page.recurrence"} />
				</LinkWithQuery>
			),
		},
	];

	const isOnline = useOnlineStatus();
	const serviceInfoItem = <ServiceInfo />;
	const userMenuItem = <DefaultUserMenu environment={environment} />;

	const isAppOnline = isOnline ? (
		<SimpleTooltip content={<FormattedMessage id={"general.app.online"} />} placement="bottom">
			<span className="text-success rioglyph rioglyph-ok-sign text-size-h3" />
		</SimpleTooltip>
	) : (
		<SimpleTooltip content={<FormattedMessage id={"general.app.offline"} />} placement="bottom">
			<span className="text-danger rioglyph rioglyph-remove-sign text-size-h3" />
		</SimpleTooltip>
	);

	return (
		<>
			<HowToUse show={showHelpDialog} setShow={setShowHelpDialog} />
			<ApplicationHeader
				label={<FormattedMessage id="general.app.name" />}
				appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
				homeRoute={<Link to={config.homeRoute || ""} />}
				navItems={navItems}
				actionBarItems={[isAppOnline, serviceInfoItem, userMenuItem]}
			/>
		</>
	);
};

export default AppHeader;
